import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Box, Button, Tab, Tabs } from '@material-ui/core';
import Card from './Card'

const useStyles = makeStyles((theme) => ({
    board: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        border: "1px solid #2196f3",
        width: "60%",
        height: "30px",
        background: "#FFFFFF",
        padding: "10px",
        borderRadius: "8px",
        margin: "2px 2%"
    }
}));

const SelectionBoard = (props) => {
    const classes = useStyles();
    const drop = (event) => {
        event.preventDefault();

        const e = event.target;

        const cardId = event.dataTransfer.getData('card_id');
        const parentBoardId = event.dataTransfer.getData('parent_board');

        const card = document.getElementById(cardId);
        if (card == null) {
            return;
        }
        card.style.width = "max-content";
        e.appendChild(card);
        
        // remove answer if item is dragged from answer board to SelectionBoard
        if (e.id == "selection-board" && parentBoardId != "selection-board") {
            const index = parentBoardId.substring(6);
            props.removeAnswer(index);
        }
    }

    const dragOver = (event) => {
        event.preventDefault();
    }

    return (
        <Paper
            id={props.id}
            onDrop={drop}
            onDragOver={dragOver}
            className={classes.board}>
            {props.children}
            {
                props.cards.map((element) => {
                    return <Card
                        key={`card-${element.value}`}
                        id={`card-${element.value}`}
                        draggable="true"
                        value={element.value}
                        updateAnswer={props.updateAnswer}
                        swapAnswers={props.swapAnswers}
                    >
                        <Typography id={`text-${element.value}`}>
                            {element.value}
                        </Typography>
                    </Card>
                })
            }
        </Paper>
    )
}


export default SelectionBoard;
