import Introduction from "./Introduction";
import Instructions from "./Instructions";
import Image from "./Image";
import CodeArea from "./CodeArea";
import CodeLine from "./CodeLine";
import Maze from "./Maze";
import React, { useState } from "react";
import ScratchPad from "./ScratchPad";
import { Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { GeneralExplanation } from "./ExerciseExplanation";
import { Chip, makeStyles, ThemeProvider } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { iconTheme } from '../../../styles/StyleProperties';
import ListExplanation from "./ListExplanation";
import StoryIntro from "./StoryIntro";
import { TableContent } from "./TableContent";

const ContentDisplay = ({ content, stdout, status }) => {
    const [hintEnabled, setHintEnabled] = useState(false)

    const useStyles = makeStyles((theme) => ({
        chip: {
            marginTop: "3%",
            marginBottom: "3%",
        }
    }));
    const classes = useStyles();

    // Here are all the content types whose content.payload are
    // later be wrapped with ReactMarkdown component
    const isReactMardownRendered = [
        "instructions",
        "introduction",
        "story",
        "story-blue",
        "listExplanation"
    ];

    // Content is processed by either:
    // - 'escape'  : if content type is listed in
    //               constant isReactMarkdownRendered
    // - 'unescape': if content type includes 'output' or 'code'
    // - 'return'  : content.payload is kept intact
    const processContent = (content) => {
        if (content.payloadType !== "markdown") return content;

        let processedContent = { ...content };

        if (!isReactMardownRendered.includes(content.type)) {

            // not a content type that contains:
            if (!["code", "output", "input"]
                .some(typeIncl => content.type.toLowerCase().includes(typeIncl))) return content;

            let codePayload = { ...content.payload };
            const code = content.payload.code || content.payload;

            // This process will attempt to 'unescape' newline and tab: '\\n', '\\t'
            // However, attempts succeed only if: 
            // - the escape sequence is not preceded by any `\` (backslash) and 
            // - not in-between double/single quotation marks ("")/('')

            const handleUnescaping = (str) => {
                // checking if str is ascii art
                if (str.startsWith("ascii:")) return str.replace(/ascii:/g, '').replace(/\\n/g, '\n').replace(/\\t/, '\t');

                // Temporary replace sequence `'d`, `'m` `'t`, `'s`, `'re`, `'ll` with temp value: ##CONTRACTION_[dmts]|re##
                // to correctly identify quoted portions
                const contractions = `[dmts]|re|ll`;

                str = str.replace(new RegExp(`'(${contractions})(?= )`, 'g'), (match) => {
                    if (match) return `##CONTRACTION_${match.slice(1)}##`;
                    else return match;
                });

                // Return quoted portion as-is and
                // Perform unescaping of certain sequences: `\\`, `\\n`, `\\t`
                return str.replace(/("[^"]+"|'[^']+')|(?<!\\)\\[nt]|\\\\(?=.)/g, (match, quoted) => {
                    if (quoted) {
                        return quoted;
                    } else {
                        if (match === '\\\\') return '\\';
                        else if (match === '\\n') return '\n';
                        else if (match === '\\t') return '\t';
                        else return match;
                    }
                }).replace(new RegExp(`##CONTRACTION_(${contractions})##`, 'g'), (match, id) => {
                    if (match) return `'${id}`;
                    else return match;
                }).replace(/ *(?=(\n|\\n|\\\\n))/g, ''); // Trim white spaces
            }

            const processedCode = handleUnescaping(code);

            content.payload.hasOwnProperty('code')
                ? codePayload.code = processedCode
                : codePayload = processedCode;
            processedContent.payload = codePayload;

            return processedContent;
        }

        // Escaping backslashes for ReactMarkdown
        processedContent.payload = content.payload.map(value => value.replace(/\\(?![nt])/g, '\\\\'));

        return processedContent;
    }

    // In order to make the content display prop standalone
    return (
        <div>
            {
                content.map((content, index) => {
                    // Content is preprocessed to provide a correct rendering
                    content = processContent(content);

                    switch (content.type) {
                        case 'instructions':
                            return <Instructions content={content.payload} payloadType={content.payloadType} />;
                        case 'codeArea':
                            return <CodeArea title={""} code={content.payload.code} mode={content.payload.mode} isEmpty={false} showLineNumbers={true} />;
                        case 'codeAreaTitle':
                            return <CodeArea title={"Code:"} code={content.payload.code} mode={content.payload.mode} isEmpty={false} showLineNumbers={true} />;
                        case 'codeExample':
                            return <CodeArea title={"Example:"} code={content.payload.code} mode={content.payload.mode} isEmpty={false} showLineNumbers={true} />;
                        case 'outputArea':
                            return <CodeArea title={""} code={content.payload.code} mode={"plain_text"} isEmpty={false} showLineNumbers={false} />;
                        case 'outputAreaTitle':
                            return <CodeArea index={index} title={"Output:"} code={content.payload.code} mode={"plain_text"} isEmpty={false} showLineNumbers={false} />;
                        case 'expectedOutput':
                            return <CodeArea title={"Expected Output:"} code={content.payload.code} mode={"plain_text"} isEmpty={false} minLines={5} showLineNumbers={false} />;
                        case 'expectedInput':
                            return <CodeArea title={"Sample Input:"} code={content.payload.code} mode={"plain_text"} isEmpty={false} minLines={5} showLineNumbers={true} />;
                        case 'explanation':
                            return <></>;
                        case 'introduction':
                            return <Introduction content={content?.payload} />;
                        case 'codeLine':
                            return <CodeLine content={content?.payload} alignment={"center"} />;
                        case 'codeLine-left':
                            return <CodeLine content={content?.payload} alignment={"left"} />;
                        case 'story':
                            return <StoryIntro content={content?.payload} color={'#000000'} />;
                        case 'story-blue':
                            return <StoryIntro content={content?.payload} color={'#3f51b5'} />;
                        case 'image':
                            return <Image content={content?.payload} />;
                        case 'maze':
                            return <Maze content={content?.payload} code={stdout} status={status} />;
                        case 'scratchPad':
                            return <ScratchPad minLines={content?.minLines} maxLines={content?.maxLines} theme={content.theme} language={content.language} initiallyCollapsed={true} />
                        case 'listExplanation':
                            return <ListExplanation content={content?.payload} />
                        case 'hint':
                            return (
                                <>
                                    {!hintEnabled && (
                                        <ThemeProvider theme={iconTheme}>
                                            <Chip className={classes.chip} color="primary" style={{ fontSize: '16px', margin: "1% 0%" }} onClick={() => setHintEnabled(true)} icon={<HelpIcon variant="contained" color="primary" />} label={"Hint"} />
                                        </ThemeProvider>
                                    )}
                                    {hintEnabled &&
                                        <React.Fragment>
                                            <ThemeProvider theme={iconTheme}>
                                                <Chip className={classes.chip} color="primary" style={{ fontSize: '16px', margin: "1% 0%" }} onClick={() => setHintEnabled(false)} icon={<HelpIcon variant="contained" color="primary" />} label={"Hint"} />
                                            </ThemeProvider>
                                            <GeneralExplanation heading={"Hint:"} explanation={content.payload} payloadType={content.payloadType} />
                                        </React.Fragment>
                                    }
                                </>
                            )
                        case 'table':
                            return <TableContent payload={content.payload} />
                        default:
                            return <Alert severity='error' variant='outlined'>
                                <Typography>{content.type} is not a valid content type </Typography>
                            </Alert>;
                    }
                })
            }
        </div>
    );
};

export default ContentDisplay;