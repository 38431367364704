import styled from '@emotion/styled';
import { Button, Grid } from '@material-ui/core';
import React from 'react';


export const CodeButtons = ({ codeCallback, isCodeRunning }) => {
    const handleCodeRun = () => codeCallback(true)
    return (
        <Grid container spacing={3}>
            <ButtonGrid item xs={12} style={{ justifyContent: "center" }}>
                <Button variant="contained" color="primary" onClick={() => handleCodeRun()} disabled={isCodeRunning}>
                    Run
                </Button>
            </ButtonGrid>
        </Grid>
    )
}

const ButtonGrid = styled(Grid)`
    display: flex;
`;