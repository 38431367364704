/* eslint-disable no-eval */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectionBoard from './SelectionBoard';
import Board from './Board';
import { Typography, Paper, Grid, Box } from '@material-ui/core';
import { GeneralText } from '../../../common/CommonStyles';

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    //color: theme.palette.text.secondary,
    height: "850px"
  },
  question: {
    padding: theme.spacing(2),
    //color: theme.palette.text.secondary
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));


const DragDropText = ({ onSubmission, index, dragDrop, text }) => {
  // TODO: Add more?
  // Component styling and screen resolution
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);
  
  // indexes where type is "input"
  const inputIndex = [];
  for (let i = 0; i < text[0].length; i++) {
    if (text[0][i].type == "input") {
      inputIndex.push(i);
    }
  }

  // This should check the academy server for the token status
  // const refresh = () => {
  //   console.log("Checking submission token status");
  // }

  const updateAnswer = (value, index) => {
    let updated = answers.slice();
    updated[index] = value;
    setAnswers(updated);
  }

  const swapAnswers = (index1, index2) => {
    var temp1 = answers[index1];
    answers[index1] = answers[index2];
    answers[index2] = temp1;
  }

  const removeAnswer = (index) => {
    answers[index] = null;
  }

  useEffect(() => {
    if (answers.length > 0) {
      onSubmission(answers, index);
    }
  }, [answers])


  return (
    <Box
      // display="flex"
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      bgcolor="background.paper"
    >
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Paper style={{ padding: "20px", width: "100%" }}>
          <div className={classes.root}>
            <SelectionBoard id="selection-board" cards={dragDrop.cards} updateAnswer={updateAnswer} swapAnswers={swapAnswers} removeAnswer={removeAnswer}/>
          </div>

          {text.map((group, groupIndex) => {
            return (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={1}
                p={1}
                bgcolor="background.paper"
              >
                {
                  group.map((component, componentIndex) => {
                    if (component.type === 'text') {
                      return (
                        <GeneralText id="sample"
                          style={{
                            fontFamily: "Courier New",
                            userSelect: "none"
                          }}
                        >
                          {component.value}
                        </GeneralText>
                      );
                    } else if (component.type === 'input') {
                      // determine the right index for each board
                      let boardIndex = 0;
                      for (let i = 0; i < inputIndex.length; i++) {
                        if (inputIndex[i] == componentIndex) {
                          boardIndex = i;
                          break;
                        }
                      }                      

                      // board
                      return (
                        <Board
                          key={`board-${boardIndex}`}
                          id={`board-${boardIndex}`}
                          depth={0}
                          updateAnswer={updateAnswer}
                          swapAnswers={swapAnswers}
                          index={index.id} />
                      )
                      // TODO: Maybe different output if label is present or not to make it easy to balance

                    } else {
                      return (
                        <Typography variant="h6"
                          style={{
                            fontFamily: "Courier New", marginLeft: 5, color: "red"
                          }}
                        >
                          {component.type} is not a valid prop type
                        </Typography>
                      );
                    }
                  })
                }
                < Typography display={"block"} />
              </Box>
            );
          })
          }
        </Paper>
      </Grid >
    </Box >
  );
};


export default DragDropText;
