import React from "react";
import TrueFalse from "./true-false/TrueFalse";
import MultiChoice from "./multichoice/MultiChoice";
import CodeInput from "./code-input/CodeInput";
import TextBoxes from "./text-boxes/TextBoxes";
import DragDrop from "./dragdrop/DragDrop";
import DragDropText from "./dragdrop/DragDropText";
import DragDropArrow from "./dragdrop/DragDropArrow";
import Alert from '@material-ui/lab/Alert';
import { Typography } from "@material-ui/core";


const SolutionInput = ({ question, onSubmission, language, index, isSecondColumn }) => {

    switch (question.type) {
        case 'true-false':
            return <TrueFalse onSubmission={onSubmission} index={index} />;
        case 'multi-choice':
            return <MultiChoice onSubmission={onSubmission} index={index} content={question.choices} formatType={question.choiceFormat} />;
        case 'code-input':
            return <CodeInput
                title={"Code Input:"}
                type={question.type}
                onSubmission={onSubmission}
                initialCode={question?.codeContent?.initialCode}
                index={index}
                language={language}
                theme={'github'}
                minLines={isSecondColumn ? 15 : 10}
                maxLines={isSecondColumn ? 15 : 10}
            />;
        case 'code-input-only':
            return <CodeInput
                title={"Code Input:"}
                type={question.type}
                onSubmission={onSubmission}
                initialCode={question?.codeContent?.initialCode}
                index={index}
                language={language}
                theme={'github'}
                minLines={isSecondColumn ? 15 : 10}
                maxLines={isSecondColumn ? 15 : 10}
            />;
        case 'text-boxes':
            return <TextBoxes onSubmission={onSubmission} index={index} content={question.textInput} />
        case 'drag-drop':
            return <DragDrop onSubmission={onSubmission} index={index} content={question.dragDrop} />
        case 'drag-drop-text':
            return <DragDropText onSubmission={onSubmission} index={index} dragDrop={question.dragDrop} text={question.textInput} />
        case 'drag-drop-arrow':
            return <DragDropArrow onSubmission={onSubmission} index={index} content={question.dragDrop} />
        default:
            return (<Alert severity='error' variant='outlined'>
                <Typography>{question.type} is not a valid Solution Input </Typography>
            </Alert>
            )
    }
};

export default SolutionInput;