import * as React from "react";
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Grid, CardActions, CardContent, Button, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";


const MultiChoiceButton = (props) => {

    const [colour, setColour] = useState("2px solid transparent");
    const [selected, setSelected] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        card: {
            width: "100%",
            margin: "8px",
            minHeight: "50px",
            maxHeight: "fit-content",
            background: "#ECECEC"
        },
        cardContent: {
            textAlign: 'left',
            color: theme.palette.text.secondary
        },
        choice: {
            fontSize: "18px",
            margin: "0px 0px"
        },
    }));

    const reset = () => {
        props.next(props.question.n + 1);
        setColour("#ECECEC");
    }

    const changeButton = () => {
        if (!selected) {
            setColour("2px solid #3f51b5");
        } else {
            setColour("2px solid #ECECEC");
        }
        setSelected(!selected);
    }

    useEffect(() => {
        props.onSelect(selected, props.answer);
    }, [selected]);

    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Card className={classes.card} style={{ border: colour }}>
                <CardActionArea onClick={changeButton} style={{ height: "100%" }}>
                    <CardContent className={classes.cardContent}>
                        {props.formatType === 'markdown' && (
                            <Typography gutterBottom className={classes.choice} component="div"><ReactMarkdown>{props.answer}</ReactMarkdown></Typography>
                        )}
                        {props.formatType !== 'markdown' && (
                            <Typography gutterBottom className={classes.choice} component="div" dangerouslySetInnerHTML={{ __html: props.answer }} />
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default MultiChoiceButton;
